<template>
    <Dialog v-model:visible="visible" modal header="Tipo" :style="{ width: '1000px'}" @hide="visible = false">
        <div class="row">
            <div class="col-md-12">
                <div>
                    <div class="card card-default card-tabs">
                    <div class="card-header p-1 pt-1">
                        <ul class="nav nav-pills">
                            <li class="nav-item"><a class="nav-link active" href="#datos" data-toggle="tab">Datos</a></li>
                            <li v-if="plantillas" class="nav-item"><a class="nav-link" href="#texto" data-toggle="tab">Plantillas Texto</a></li>
                            <li v-if="plantillas" class="nav-item"><a class="nav-link" href="#trabajos" data-toggle="tab">Plantillas Trabajos</a></li>
                        </ul>
                    </div>
                </div>
                <br>
                <div class="tab-content">
                    <div class="active tab-pane container-grid" id="datos">
                        <div class="container-grid">
                            <div>
                                <label>Nombre</label>
                                <input type="text" v-model="nombre" style="width: 30%;">
                            </div>
                            <div style="margin-top: 50px;">
                                <label>Compañías</label>
                                <MultiSelect
                                    :maxSelectedLabels="2"
                                    v-model="ids_seleccionados"
                                    :options="companias"
                                    optionValue="id"
                                    optionLabel="nombre"
                                    placeholder="Compañías"
                                    class="w-full md:w-20rem fixed-size"
                                />
                            </div>
                            <button style="width: 100px; margin-top: 20px;" class="btn btn-primary" @click="guardarDatos();this.$parent.obtenerTipos() ;this.visible=false">Guardar</button>
                        </div>
                    </div>
                    <div class="tab-pane  ov" id="texto">
                        <div class="card">
                            <div class="card-header">
                                Nueva plantilla texto
                            </div>
                            <div class="card-body">
                                <div class="plant">
                                    <div>
                                        <label for="name">Nombre</label>
                                        <input type="text" v-model="nombreNuevaPlantilla">
                                    </div>
                                    <div>
                                        <label for="name">Tipo</label>
                                        <select class="form-control frm" v-model="tipoPlantillaTexto" placeholder="Elige un tipo">
                                            <option value="Visita">Visita</option>
                                            <option value="Presupuesto">Presupuesto</option>
                                            <option value="Factura">Factura</option>
                                        </select>
                                    </div>
                                    <div>
                                        <button class="btn btn-light btn-sm bt" style="border: 1px solid;" @click="añadirPlantillaTexto()">Añadir</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Tipo</th>
                                        <th>Compañías</th>
                                        <th>Texto</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(plantilla, index) in plantillasTexto" :key='plantilla' class="bd">
                                        <td><input style=" font-size: small;" type="text" v-model="textNombre[index]"></td>
                                        <td><select style="width: 90%; height: 90%; font-size: small;" class="form-control" v-model="textTipo[index]">
                                            <option value="Visita">Visita</option>
                                            <option value="Presupuesto">Presupuesto</option>
                                            <option value="Factura">Factura</option></select></td>
                                        <td><MultiSelect 
                                            :maxSelectedLabels="2"
                                            :options="seleccionadas"
                                            optionValue="id"
                                            optionLabel="nombre"
                                            placeholder="Compañías"
                                            v-model="textIdCompanyias[index]"
                                            style="width: 150px; font-size: small;"/></td>
                                        <td><textarea rows="5" cols="30" v-model="textTexto[index]"></textarea></td>
                                        <td style="display: grid;"><button class="btn btn-sm btn-success"><i class="fas fa-save" @click="guardarDatosPlantillaTexto(plantilla.id_tipo_plantilla, index)"></i></button>
                                            <button class="btn btn-sm btn-danger" @click="dialId=plantilla.id_tipo_plantilla" data-toggle="modal" data-target="#confirmar" style="margin-top: 5px;"><i class="fas fa-trash-alt"></i></button></td>
                                    </tr>
                                </tbody>
                            </table>     
                        </div>
                        <div class="modal fade" id="confirmar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">PWGS</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    ¿Eliminar plantilla?
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                    <button type="button" class="btn btn-primary" @click="eliminarPlantillaTexto(this.dialId)" data-dismiss="modal">OK</button>
                                </div>
                                </div>
                            </div>
                        </div>                 
                    </div>
                    <div class="tab-pane" id="trabajos">
                        <div class="card">
                            <div class="card-header">
                                Nueva plantilla Trabajos
                            </div>
                            <div class="card-body">
                                <form>
                                    <div class="form-group">
                                        <label for="nombre">Nombre</label>
                                        <input v-model="trSelNombre" type="text" class="form-control" id="nombre" style="width: 30%;">
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <label for="compania">Compañía</label>
                                            <select v-model="trSelComp" id="compania" style="font-size: small; width: 100%; height: 30px;">
                                                <option value="Selecciona">Selecciona compañía</option>
                                                <option v-for="comp in seleccionadas" :key="comp.id" :value="comp.id">{{ comp.nombre }}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="baremo">Plantilla baremo</label>
                                            <select v-model="trSelBar" id="baremo" style="font-size: small; width: 100%; height: 30px;">
                                                <option value="Selecciona">Selecciona plantilla</option>
                                                <option v-for="bar in plantillasBaremo" :key="bar.id" :value="bar.id">{{ bar.nombre }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group" style="margin-top: 10px; display: grid;">
                                        <label for="operario">Operario</label>
                                        <select v-model="trSelOpe" id="operario" style="font-size: small; width: 50%; height: 30px;">
                                                <option value="Selecciona">Operario que se cite</option>
                                                <option v-for="operario in operarios" :key="operario.id_operario" :value="operario.id_operario">{{ operario.nombre }}</option>
                                            </select>
                                    </div>
                                    <button class="btn btn-sm btn-ligth" type="button" style="border: 1px solid grey;" @click="crearPlantillaTrabajo()">Insertar</button>
                                </form>                        
                            </div>
                        </div>
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Compaía</th>
                                    <th>Plantilla</th>
                                    <th>Operario</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(plantilla, index) in plantillasTrabajo" :key="plantilla">
                                    <td>{{ trNombre[index] }}</td>
                                    <td>{{ trCompania[index] }}</td>
                                    <td>{{ trBaremo[index] }}</td>
                                    <td>{{ trOperario[index] }}</td>
                                    <td><button class="btn btn-sm btn-danger" @click="dialIdTr=plantilla.id_plantilla_trabajo" data-toggle="modal" data-target="#confirmartr" style="margin-top: 5px;"><i class="fas fa-trash-alt"></i></button></td>
                                </tr>
                            </tbody>
                        </table> 
                        <div class="modal fade" id="confirmartr" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">PWGS</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    ¿Eliminar plantilla?
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                    <button type="button" class="btn btn-primary" @click="eliminarPlantillaTrabajo(this.dialIdTr)" data-dismiss="modal">OK</button>
                                </div>
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
                </div>
                <div style="display: flex;border-top: 1px solid grey; margin-top: 10px; justify-content: end;"><button class="btn btn-light btn-sm" style="border: 1px solid; margin-top:15px;" @click="this.visible=false">Cerrar</button></div>
            </div>
        </div>
    </Dialog>
</template>


<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import Dialog from 'primevue/dialog';
import MultiSelect from 'primevue/multiselect';

export default ({
    props: ['tipo'],
    components: {
        Dialog,
        MultiSelect,
    },
    data() {
        return {
            dialId:'',
            dialIdTr:'',
            textNombre:[],
            textTipo:[],
            textIdCompanyias:[],
            textTexto:[],
            plantillasTexto:[],
            trNombre:[],
            trOperario:[],
            trCompania:[],
            trBaremo:[],
            plantillasTrabajo:[],
            visible: false,
            nombre: this.tipo.nombre,
            companias: [],
            seleccionadas: [],
            ids_seleccionados:[],
            plantillasBaremo:[],
            tipoPlantillaTexto:'',
            nombreNuevaPlantilla:'',
            trSelComp:'Selecciona',
            trSelBar:'Selecciona',
            trSelOpe:'Selecciona',
            trSelNombre:'',
            operarios:[],
            plantillas: false,
        };
    },
    mounted() {        
        this.cargarCompanias();
        this.cargarPlantillasTexto();
        this.cargarPlantillasTrabajo();
        this.cargarOperarios();
        this.comprobarPlantillas();
    },
    watch:{
        trSelComp(){
            this.cargarPlantillasBaremo();
        }
    },
    methods: {
        async comprobarPlantillas(){
            const api = new PwgsApi();
            const ia = await api.get('parametros-pwgs/gestion_tipos_plantilla');
            if(ia['gestion_tipos_plantilla']==1){
                this.plantillas=true;
            }
        },
        async crearPlantillaTrabajo() {
            if (this.trSelNombre == ''){
                alert('Debes introducir un nombre');
            }
            else{
                const api = new PwgsApi();
                const body = {'nombre_plantilla_trabajo': this.trSelNombre};
                if(this.trSelComp != 'Selecciona'){
                    body.companyias_id_companyia = this.trSelComp;
                }
                if(this.trSelBar != 'Selecciona'){
                    body.plantillas_baremo_id = this.trSelBar;
                }
                if(this.trSelOpe != 'Selecciona'){
                    body.operarios_id_operario = this.trSelOpe;
                }
                const resp = await api.post('tipos-servicio/'+this.tipo.id+'/plantillas-trabajo', body);
                console.log('resp',resp);
                this.cargarPlantillasTrabajo();
            }
        },
        async cargarOperarios(){            
            const api = new PwgsApi();
            const resp = await api.get('tipos-servicio/'+this.tipo.id+'/operarios');
            if(resp[0]== undefined || resp[0]==null){
                this.operarios = [];
            }else{
                this.operarios = resp;
            }
        },
        async cargarPlantillasBaremo(){
            if(this.trSelComp == 'Selecciona'){
                this.plantillasBaremo = [];
            }else{
                const api = new PwgsApi();
                const resp = await api.get('tipos-servicio/'+this.tipo.id+'/plantillas-baremo/'+this.trSelComp);
                if(resp[0]==undefined || resp[0] == null){
                    this.plantillasBaremo = [];
                }else{
                    this.plantillasBaremo = resp;
                }
            }
        },
        async cargarPlantillasTrabajo(){
            const api = new PwgsApi();
            const resp = await api.get('tipos-servicio/'+this.tipo.id+'/plantillas-trabajo');
            this.trBaremo = [];
            this.trNombre = [];
            this.trOperario = [];
            this.trCompania = [];
            if (resp.datos == ""){
                this.plantillasTrabajo = [];
            }else{
                this.plantillasTrabajo = resp.datos;
                if(this.plantillasTrabajo.length === undefined){
                    this.plantillasTrabajo = [this.plantillasTrabajo];
                }
                for(var i in this.plantillasTrabajo){
                    this.trNombre.push(this.plantillasTrabajo[i].nombre_plantilla_trabajo);
                    this.trCompania.push(this.plantillasTrabajo[i].nombre_compania);                    
                    this.trBaremo.push(this. plantillasTrabajo[i].nombre_plantilla_baremo);
                    this.trOperario.push(this. plantillasTrabajo[i].nombre_operario);
                }

            }

        },
        async guardarDatosPlantillaTexto(id, index){            
            const api = new PwgsApi();
            const body = {'nombre_plantilla':this.textNombre[index], 'tipo_plantilla':this.textTipo[index],'texto_plantilla':this.textTexto[index]};
            await api.put('tipos-servicio/'+this.tipo.id+'/plantillas-texto/'+id,body);
            if(this.textIdCompanyias[index].length<1){
                await api.delete('tipos-servicio/'+this.tipo.id+'/plantilla-texto/'+id+'/companias');
            }
            else{
                const body2 = {'companyias_id_companyia':this.textIdCompanyias[index]};
                await api.post('tipos-servicio/'+this.tipo.id+'/plantillas-texto/'+id+'/companias',body2);
            }            
        },
        async eliminarPlantillaTexto(id){
            const api = new PwgsApi();
            await api.delete('tipos-servicio/'+this.tipo.id+'/plantilla-texto/'+id);
            this.cargarPlantillasTexto();
        },
        async eliminarPlantillaTrabajo(id){
            const api = new PwgsApi();
            await api.delete('tipos-servicio/'+this.tipo.id+'/plantilla-trabajo/'+id);
            this.cargarPlantillasTrabajo();
        },
        async cargarPlantillasTexto(){
            const api = new PwgsApi();
            const res = await api.get('tipos-servicio/'+this.tipo.id+'/plantillas-texto');
            this.plantillasTexto = [];
            this.textNombre = [];
            this.textTipo = [];
            this.textTexto = [];
            this.textIdCompanyias = [];
            if (res.datos == ""){
                this.plantillasTexto = [];
            }else{
                this.plantillasTexto = res.datos;
                if(this.plantillasTexto.length === undefined){
                    this.plantillasTexto = [this.plantillasTexto];
                }
                for(var i in this.plantillasTexto){
                    this.textNombre.push(this.plantillasTexto[i].nombre_plantilla);
                    this.textTipo.push(this.plantillasTexto[i].tipo_plantilla);
                    this.textTexto.push(this.plantillasTexto[i].texto_plantilla);
                    const id_planti = this.plantillasTexto[i].id_tipo_plantilla;
                    const respCompsText = await api.get('tipos-servicio/'+this.tipo.id+'/plantillas-texto/'+id_planti+'/companias');
                    const plants = respCompsText.datos;
                    if (plants == ""){
                        this.textIdCompanyias.push([]);
                    }else{
                        var ids = [];
                        if(plants.length === undefined) {
                            ids.push(plants.companyias_id_companyia);
                        }else{
                            for(var j in plants){
                                ids.push(plants[j].companyias_id_companyia);
                            }   
                        }
                        this.textIdCompanyias.push(ids);
                    }
                }

            }
        },
        async añadirPlantillaTexto(){
            const api = new PwgsApi();
            const resp = await api.post('tipos-servicio/'+this.tipo.id+'/plantillas-texto',{'nombre_plantilla':this.nombreNuevaPlantilla,'tipo_plantilla':this.tipoPlantillaTexto});
            console.log('resp', resp);
            this.cargarPlantillasTexto();
        },
        async guardarDatos() {
            const api = new PwgsApi();
            if (this.nombre !=  this.tipo.nombre){
                const resp2 = await api.put('tipos-servicio/'+this.tipo.id,{'nombre':this.nombre});
                console.log('resp2', resp2);
            }
            var selecs = [];
            for(var i in this.ids_seleccionados){
                selecs.push(this.ids_seleccionados[i]);
            }     
            var body={'companyias_id':selecs};
            const resp = await api.post('tipos-servicio/'+this.tipo.id+'/companias',body);
            console.log('resp', resp);
            
        },
        hacerVisible() {
            this.visible = true;
        },
        async cargarCompanias() {
            try {
                const api = new PwgsApi();
                const resp = await api.get('companias');
                this.companias = Array.isArray(resp.datos) ? resp.datos : []; 
                this.cargarSeleccionadas();
            } catch (error) {
                console.error('Error al cargar compañías:', error);
            }
        },
        async cargarSeleccionadas() {
            try {
                const api = new PwgsApi();
                const resp = await api.get('tipos-servicio/' + this.tipo.id + '/companias');
                const seleccionadasData = Array.isArray(resp.datos) ? resp.datos : [resp.datos]; 
                this.seleccionadas = this.matchSelectedCompanias(seleccionadasData);
            } catch (error) {
                console.error('Error al cargar seleccionadas:', error);
            }
        },
        matchSelectedCompanias(seleccionadasData) {
            if (!Array.isArray(seleccionadasData)) {
                seleccionadasData = [seleccionadasData]; // Convertir a array si es un objeto
            }
            
            const seleccionadasIds = seleccionadasData.map(item => {
                return item.companyias_id ? String(item.companyias_id) : null;
            }).filter(id => id !== null);

            this.ids_seleccionados = seleccionadasIds;

            const matchedCompanias = this.companias.filter(compania => {
                return seleccionadasIds.includes(String(compania.id));
            });
            return matchedCompanias;
        }
    }
});
</script>



<style>
.container-grid {
    display: grid;
}
.fixed-size {
    width: 50%;
}
.container-grid > * {
    display: grid;
}
.plant{
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.plant > *{
    display: flex;
    flex-direction: column;
    align-self: end;
}
.frm{
    width: 200px;
    height: 30px;
}
.bt{
    width: 80px;
}
.plantTr{
    display: grid;
    grid-template-rows: 1.5fr ;
}
 .ov  textarea, 
 .ov  input,
 .ov  select {
    width: 100%;
    box-sizing: border-box;
}
</style>
